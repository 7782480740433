@font-face {font-family: "Sofia"; src: url("//db.onlinewebfonts.com/t/1c741530cee363cb7b6d883959d695e8.eot"); src: url("//db.onlinewebfonts.com/t/1c741530cee363cb7b6d883959d695e8.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/1c741530cee363cb7b6d883959d695e8.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/1c741530cee363cb7b6d883959d695e8.woff") format("woff"), url("//db.onlinewebfonts.com/t/1c741530cee363cb7b6d883959d695e8.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/1c741530cee363cb7b6d883959d695e8.svg#Sofia") format("svg"); }
@font-face {font-family: "Sofia Pro Light"; src: url("//db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.eot"); src: url("//db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.woff") format("woff"), url("//db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#Sofia Pro Light") format("svg"); }
@font-face {font-family: "Sofia Pro Bold"; src: url("//db.onlinewebfonts.com/t/05428692254b58ccb090358a3dfecfff.eot"); src: url("//db.onlinewebfonts.com/t/05428692254b58ccb090358a3dfecfff.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/05428692254b58ccb090358a3dfecfff.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/05428692254b58ccb090358a3dfecfff.woff") format("woff"), url("//db.onlinewebfonts.com/t/05428692254b58ccb090358a3dfecfff.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/05428692254b58ccb090358a3dfecfff.svg#Sofia Pro Light") format("svg"); }

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

body {
  font-family: 'Sofia Pro Light';
  background-color: #eee;
  padding: 1.5rem;
  margin-top: 6rem;
}

footer {
  margin-top: 2rem;
  font-size: 12px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
