$borderColor: #ccc;
$red: rgb(225 49 49);


.calculator {
  text-align: center;
  padding: 2rem 1rem 1rem;
  background-color: white;
  border-radius: 15px;
  width: 100%;

  @screen md {
    min-width: 400px;
    width: min-content;
  }
}

.sale-tag {
  background-color: $red;
  color: white;
  padding: 2.5rem 2rem 2rem ;
  border-radius: 10px 10px 0 0;
  border-top: 1px solid $borderColor;
  border-left: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
}

.sale-tag h2 {
  font-size: 2rem;
  margin: 0;
  letter-spacing: 1px;  

  @screen md {
    font-size: 2.5rem;
  }
}

.sale-tag p {
  font-size: 1.2em;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 0.75rem;
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  background-color: rgb(238 239 244);
  border-radius: 0 0 10px 10px;
  padding: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid $borderColor;
  border-left: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
}

.controls:first-child {
  width: 100%;
}

.controls > div {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.controls .taxrate, 
.controls .discounts {
  flex-direction: column;
  align-items: start;
}

label {
  font-weight: 500;
  font-size: 14px;
}

input[type="number"] {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 0.5rem;
  width: 5rem;

  &.dollar-value {
    width: 70%;
  }
}

.incrementButton {
  background-color: $red;
  border: 1px solid white;
  padding: 0.75rem;
  color: white;
  cursor: pointer;
  font-weight: bold;
  border-radius: 0.8rem;
  white-space: nowrap;
}

.controls .controls__apart {
  justify-content: space-between;
}

.prepend-dollar {
  position: relative;
  left: 2rem;
  font-size: 14px;
  width: 27px;
  text-align: left;
  display: inline-block;
}

.prepend-dollar + input {
  padding-left: 2.2rem;
}

.appendicon {
  position: relative;
  right: 1.5rem;
  font-size: 14px;
}

.gearIcon {
  width: 20px;
  height: 20px;
  fill: white;
}

.settingsButton {
  background-color: transparent;
  border-radius: 0.4rem;
  border: 0;
  cursor: pointer;
}

.settingsWrapper {
  text-align: right;
  margin-bottom: -2.5rem;
  margin-right: 1rem;
  position: relative;
}

.dropdown {
  padding: 0.5rem 1rem;
}

.text-small {
  font-size: 14px;
}

.toggleswitch {
  position: relative;
  top: 0.35rem;
  zoom: 1.2;
  left: 0.5rem;
}

.toggleswitch input {
  left: 0;
}

.taxrateinput {
  margin-top: 0.5rem;
}

.taxrateinput label {
  padding-right: 0.6rem;
}
