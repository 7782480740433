$red: rgb(225 49 49);

.nav {
    @apply 
        tw-absolute
        md:tw-fixed 
        tw-z-10 
        tw-bg-white 
        tw-top-2 
        tw-left-6 
        tw-px-4
        tw-pt-4 
        tw-pb-6 
        tw-border-b-[1px] 
        tw-border-solid 
        tw-border-gray-300 
        tw-rounded-2xl;

        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.08);
    width: calc(100% - 3rem);
    
    @screen md {
        width: calc(100% - 2.75rem);
    }
    
    .nav__logo {
        @apply tw-text-2xl;

        font-family: 'Sofia Pro Bold';

        @screen md {
        @apply tw-text-3xl;
        }
    }
    .nav__logo-red {
        color: $red;
    }
    .nav__slogan {
        @apply tw-block
            tw-text-sm;
    }
}