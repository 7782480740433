
.seo-blurb {
  max-width: 30rem;
  background-color: white;
  padding: 1rem;
  border-radius: 20px;
  
  .seo-blurb__title {
    @apply tw-text-2xl 
      tw-font-bold 
      tw-mb-2;
  }

  .seo-blurb__list {
    @apply tw-list-decimal 
      tw-ml-4;
  }
}

.math-formula {
  @apply tw-block
    tw-mb-4
    tw-text-sm;
}
